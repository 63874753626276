@import '../../global.scss';

.intro{
    background-color: white;
    display: flex;
    @include mobile {
        flex-direction: column;
        align-items: center;
    }
    .left{
        flex: 0.5;
        background-color: white;
        overflow: hidden;
        

        .imgContainer{
            width: 100%;
            //height: 700px;
            background-image: linear-gradient(20deg, rgb(92, 103, 141)
            38.3%, rgb(175, 73, 170) 100%);
            border-radius: 50%;

            @include mobile{
                align-items: flex-start;
            }
           
            img{
                width: 100%;
                opacity: 0.5;
                @include mobile{
                    height: 50%;
                }
            }
        }
    }

    .right{
        flex: 0.5;
        background-color: white;
        position: relative;
        @include mobile {
           display: flex;
           flex-direction: column;
           justify-content: center;
           align-items: center;
        }


        .wrapper{
            width: 100%;
            height: 100%;
            padding-left: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include mobile {
                padding-left: 0;
                align-items: center;
            }


            h1{
                font-size: 60px;
                margin: 10px 0;
                @include mobile {
                    font-size: 40px;
                }
            }
            h2{
                font-size: 35px;
                margin-bottom: 10px;
                @include mobile {
                    font-size: 20px;
                }
            }
            h3{
                font-size: 30px;

                span{
                    
                    font-size: inherit;
                    color: $mainColor
                }

                .ityped-curser{
                    animation: blink 1s infinite;
                }

                @keyframes blink {
                    100%{
                        opacity: 0;
                    }
                }
            }
        }
        a{
            position: absolute;
            bottom: 10px;
            left: 40%;
            @include mobile {
                position: initial;
            }
            img{
                width: 30px;
                animation: arrowBlink 2s infinite;
            }
            @keyframes arrowBlink {
                100%{
                    opacity: 0;
                }
            }
        }
    }
} 