@import '../../global.scss';


.portlist{
    font-size: 30px;
    margin-right: 50px;
    padding: 7px;
    border-radius: 10px;
    cursor: pointer;
    color: inherit;

    &.active{
       background-color: $mainColor;
       color: white;
     
    }
 }