@import '../../global.scss';

.contact{
    background-color: white;
    display: flex;

    @include mobile {
        flex-direction: column;
    }
    .left{
        flex: 1;
        background-color: salmon;
        display: flex;
        transition: all 1s ease;
      
        
        .top{
            flex: 1;
           
            opacity: 1;
            transition: all 2s ease;
            display: flex;
           .one{
               flex: 1;
               display: flex;
           
              .ok{
                flex: 1;
                background-color: $mainColor;
              }
              .ko{
                flex: 1;
                background-color: cyan;
              }
           }
           .two{
               flex: 1;
               background-image: linear-gradient(75deg, rgb(92, 103, 141)
               38.3%, rgb(175, 73, 170) 100%);
           }
        }
        .bottom{
           flex: 1;
           transition: all 3s ease;
           &:hover{
               flex: none;
                background-color: $mainColor;
           }
       }
       
    }
    .right{
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h2{
            font-size: 30px;
        }

        form{
            width: 70%;
            height: 70%;
            flex-direction: column;
            display: flex;
            align-items: center;
            justify-content: space-around;
        }

        input{
            width: 300px;
            height: 30px ;
            font-size: 14px;
            border: 5px solid lightgray;

            @include mobile {
                width: 200px;
                height: 20px;
            }

        }

        textarea{
            width: 300px;
            height: 200px;
            font-size: 14px;
            border: 5px solid lightgray;

            border-radius: 5%;

            @include mobile {
                width: 200px;
                height: 100px;
            }
        }

        button{
            width: 150px;
            height: 30px;
            color: whitesmoke;
            background-color: crimson;
            border: none;
            border-radius: 10px;
            font-weight: 500;
            cursor: pointer;
          
       
        }
    }
}