@import '../../global.scss';



.portfolio{ 
   background-image: linear-gradient(75deg, rgb(92, 103, 141)
   38.3%, rgb(175, 73, 170) 100%);
   display: flex;
   flex-direction: column;
   align-items: center;
   

   h1{
      margin-top: 50px; 
      margin-bottom: 15px; 
      font-size: 50px; 
      color: white;

      @include mobile{
         font-size: 20px;
      }
      
   }

   ul{
      margin: 10px;
      padding: 0;
      list-style: none;
      display: flex;
      color: white;

      @include mobile{
        flex-wrap: wrap;
        justify-content: center;
      }
      li{
         font-size: 30px;
         margin-right: 50px;
         padding: 7px;
         border-radius: 10px;
         cursor: pointer;
         color: inherit;

         &.active{
            background-color: $mainColor;
            color: white;
          
         }
      }
   }

.container{
   width: 90%;
   //padding: 10px;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-wrap: wrap;
   @media screen and (max-width: 970px) {
      width: 90%;
    }
   @media screen and (min-width: 870px) {
      width: 100%;
    }

   .item{
      width: 250px;
      height: 170px;
      border-radius: 20px;
      border: 0px solid lightgray;
      margin: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      transition: all .5s ease;

      @include mobile {
         width: 130px;
         height: 100px;
      }
      h3{
         position:absolute;
         font-size: 30px;
         color: black;
      }
      label{
         position:absolute;
         font-size: 20px;
         color: black;
      }
      .cont{
         position:absolute;
         font-size: 20px;
         color: black;
         //width: 100%;
         bottom: 0;
         background-color: white;
        // opacity: 0.6;
         display: flex;
         text-align: center;
         vertical-align: center;
         align-items: center;
         transition: all 2s ease;
         transition: all 1s ease;
       a{
          //margin-left: 80px;
          color: rgb(4, 4, 255);
          text-align: center;
          text-decoration: none; 
          height: inherit;
         
         
       }
       &:hover{
          
         height: 30px;
         opacity: 1;
         
         a{
           color: rgb(4, 4, 255);
          // opacity: 0.7;
         }
      }
     
        
      }
      img{
         width: 100%;
         height: 100%;
         object-fit: cover;
         z-index: 1;
         transition: all 500ms ease;
      }

      &:hover{
         background-color: white;
       // width: 270px;
       // height: 200px;
         border-radius: 20%;
         

         img{
            opacity: 0.1;
            z-index: 0;
           
         }

         h3{
            
            font-size: 30px;

         }

         
      }
     
   }
}
}
label{
   cursor: pointer;
}