@import './global.scss';

@keyframes opac {
    0% {
      opacity: 0;
      background-image: linear-gradient(75deg, rgb(92, 103, 141)
      38.3%, rgb(175, 73, 170) 100%);
    }
    100% {
      opacity: 1;
     
    }
   
}

body{
    animation: opac ease 8s;
    animation-iteration-count: 1;
}

.app {
    height: 100vh;

        .sections{
            box-sizing: content-box;
            width: 100%;
            height: calc(100vh - 70px);
            background-color: lightsalmon;
            position: relative;
            top: 70px;
            scroll-behavior: smooth;
            scroll-snap-type: y mandatory;
            scrollbar-width: none;
            &::-webkit-scrollbar{
                display: none; 
            } 

            > *{
                width: 100%;
                height: calc(100vh - 70px);
                scroll-snap-align: start;
                @media screen and (max-width: 1100px) {
                    height: fit-content;                  
                }
            
            }
        }
}