@import '../../global.scss';


.topbar{
    width: 100%;
    height: 70px;
    background-color: white;
    color: $mainColor;
    position: fixed;
    top: 0; 
    z-index: 3;
    transition: all 2s ease;

    .wrapper{
        padding: 10px 30px;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .left{
            display: flex;
            align-items: center;

            .logo{
                font-size: 40px;
                font-weight: 700;
                text-decoration: none;
                color: inherit;
                margin-right: 40px;
            }

            .itemContainer{
                display: flex;
                align-items: center;
                margin-left: 30px;

                @include mobile {
                    display: none;
                }

                .icon{
                    font-size: 18px;
                    margin-right: 5px;
                }

                span{
                    font-size: 15px;
                    font-weight: 500;
                }
            }
        }

            .hub{
                margin-left: 6px;
                height: 30px;
                width: 30px;
            }

            a{
                cursor: pointer;
            }
        .right{
            display: flex;


            .hamburger{
                width: 32px;
                height: 25px;
                margin-left: 40px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                cursor: pointer;

                span{
                    width: 100%;
                    height: 3px;
                    background-color: $mainColor;
                    transform-origin: left;
                    transition: all 4s ease;
                }
            }
        }
    }

    &.active{
        background-color: $mainColor;
        color: white;


        .hamburger{
            span{
                
                &:first-child{
                    background-color: white;
                    transform: rotate(45deg);
                }
                &:nth-child(2){
                        opacity: 0;
                }
                &:last-child{
                    background-color: white;
                    transform: rotate(-45deg);
                    
                }
            }
            span::-ms-expand {
                display: none;
            }
        }
    }
}